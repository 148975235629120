<template>
    <div>
        <div class="search">
            <div>
                 <div class="a-inline-block">
                    <label for="">月份</label>
                    <a-select v-model="onMonth"  class="ant-select-lg">
                        <a-select-option  :value="item" v-for="(item,index) in monthList" :key="index">{{item}}</a-select-option>
                    </a-select>
                 </div>
                 <div class="a-inline-block">
                    <label for="">工人姓名</label>
                     <a-input v-model="onTemp" size="large" placeholder="请输入工人姓名" />
                  <!-- <a-select v-model="onTemp" class="ant-select-lg" >
                        <a-select-option :value="item.id" v-for="(item,index) in tempList" :key="index">{{item.name}}</a-select-option>
                </a-select> -->
                 </div>
                 <!-- <div class="a-inline-block">
                    <label for="">技能名称</label>
                  <a-select v-model="onSkill" class="ant-select-lg">
                        <a-select-option :value="item.id" v-for="(item,index) in skillList" :key="index">{{item.name}}</a-select-option>
                </a-select>
                 </div> -->
                <div class="a-inline-block">
                    <a-button size="large" type="primary" @click="onSearch">搜索</a-button>
                    <a-button size="large" @click="reset">重置</a-button>
                </div>
            </div>
        </div>
      
         <div style="text-align:right;">
               <!-- <div class="a-inline-block" @click="$refs.annexFile.click()">
            <img src="@/assets/imgs/tMenu4.png" alt=""/><label for="">导入</label>
        </div> -->
             <div class="a-inline-block" @click="excelout">
                <img src="@/assets/imgs/tMenu4.png" alt=""><label for="">导出</label>
            </div>
        </div>
         <input
          type="file"
          id="annex"
          style="display: none"
          ref="annexFile"
          @change="annexUpload($event)"
        />
        <a-table :columns="columns" :data-source="data" class="table" :pagination="pagination">
           <span slot="num" slot-scope="text, record, index">
                {{(pagination.current-1)*pagination.pageSize+parseInt(index)+1}}
            </span>
            <template slot="Action" slot-scope="text,record">
                <div style="text-align:left;">
                        <span class="showSpan" v-for="(itm,idx) in record.attendance" :key="idx">
                        {{itm.date}}<span class="lineSpan">|</span>{{itm.sname}}<span class="lineSpan">|</span>{{itm.time}}<span class="lineSpan">|</span>{{itm.money}}
                </span>
                </div>
               
            </template>
        </a-table>
    </div>
</template>
<script>
import {requestXml,ajaxUrl,fileUpload} from '../../../assets/js/request';
export default {
    data() {
        return {
            columns:[
                { title: '序号', dataIndex: 'num', width: 70, align: 'center',
                    scopedSlots: { customRender: 'num' },
                },
                { title: '姓名',width:120,align:'center', dataIndex: 'tname', key: '1'},
                { title: '当月总工时',width:120,align:'center', dataIndex: 'stime', key: '2'},
                { title: '当月总薪资',width:120,align:'center', dataIndex: 'smoney', key: '4'},
                { title: '出勤情况(工作时间|工作内容|工作时长|当日薪资)', align:'center',scopedSlots: { customRender: 'Action' }, key: '6'},
                { title: '备注', width:150,align:'center',dataIndex: 'remarks', key: '5'},
            ],
    
            data:[],
            // 分页
            pagination: {
                current: 1,
                total: 100,
				pageSize: 10, // 默认每页显示数量
				showSizeChanger: true, // 显示可改变每页数量
				pageSizeOptions: ['10', '20', '50', '100'], // 每页数量选项
                showQuickJumper: true,
				showTotal: total => `共 ${total} 条`, // 显示总数
                onChange: (current) => this.changePage(current), //点击当前页码
                onShowSizeChange: (current, pageSize) => {//设置每页显示数据条数，current表示当前页码，pageSize表示每页展示数据条数
                    console.log(pageSize);
                    this.showSizeChange(current, pageSize);
                },
			},
            skillList:'', //技能列表
            onSkill:'',
            monthList:'', //可选月份列表
            onMonth:'',
            tempList:'',  //工人列表
            onTemp:'',
              search:'',  //搜索条件
        };
    },
    mounted: function(){
        this.getMonth();
        // this.getTemp();
        this.getSkillid();
    },
    methods: {
        // 搜索
        onSearch(){
            this.pagination.current=1;
            this.getData();
        },
        reset(){
            this.onSkill = null;
            this.onTemp = null;
            this.getData();

        },
             // 获取工人列表
        getTemp(){
             requestXml("/jieb/Temporary/tempbaseshow","POST",(res) => {
                this.tempList = res.list;
            },{"page":{"curpage":1,"pagesize":9999}})
        },
        // 获取考勤月份
        getMonth(){
            requestXml("/jieb/Attendance/month","POST",(res) => {
                console.log(res);
                this.monthList=res;
                this.onMonth=res[0];
                this.getData();
            })
        },
         // 获取技能分类
        getSkillid(){
             requestXml("/jieb/Temporary/skillshow","POST",(res) => {
                this.skillList=res.list;
                // console.log(this.skillid);
                // console.log(JSON.stringify( this.skillid));
                // console.log(this.skillid);
            },{"page":{"curpage":1,"pagesize":999999}})
        },
         // 导入
         annexUpload(e){
            fileUpload(e,(res) => {
                console.log(res);
                requestXml("/jieb/Attendance/excelinput","POST",(res) => {
                console.log(res);
                this.annexModel=false;
                this.$refs.annexFile.value =''
                this.getData();
            },{id:res.id})
            })
        },
        // 导出
        excelout(){
            window.open(ajaxUrl+"/jieb/Attendance/attendance?search="+JSON.stringify(this.search));  
        },
        // 选择跟进人
        selFollow(){
            if(this.selectedRows.length<1){
                this.$message.info('请选择工人');
                return false;
            }else{
               this.showFollow=true;
            }
        },
        //设置跟进人 
        setFollow(){
            if(!this.onStaff){
                this.$message.info('请选择跟进人');
                return false;
            }else{
            let idArr=[];
            let sel=this.selectedRows;
            for(let i=0;i<sel.length;i++){
                idArr.push(sel[i].id);
            }
            requestXml("/jieb/Temporary/followup","POST",(res) => {
               if(res=="success"){
                this.$message.success('分配成功');
                this.showFollow=false;
                this.onStaff='';
                this.getData();
               }
            },{id:idArr,userid:this.onStaff})
            }

        },
        // 获取成员
          getStaff(){
            requestXml("/scrm/Staff/getList","POST",(res) => {
                this.staff=res.list;
            },{"page":{"curpage":1,"pagesize":999999}})
        },
        showStatus(res){
            this.onTemp=res;
            this.visible=true;
        },
        editStatus(){
             requestXml("/jieb/Temporary/status","POST",(res) => {
                console.log(res)
                this.getData();
            },{"id": this.onTemp.id,'time': Math.round(this.endTime/1000)})
        },
        getData() {
            requestXml("/jieb/Attendance/attendanceshow","POST",(res) => {
                console.log(res);
                this.data = res.list;
                this.search=res.search;
                this.pagination.total = Number(res.page.total);
                this.pagination.pageSize = Number(res.page.pagesize);
            },{date:this.onMonth,'name':this.onTemp,'skillname':this.onSkill,"page":{"curpage":this.pagination.current,"pagesize":this.pagination.pageSize}})
        },
        // 分页
        changePage(current){
            //current参数表示是点击当前的页码，
            console.log(current)
            this.pagination.current = current;
            this.getData(); //向后端发送请求
        },
        showSizeChange(current, pageSize) {
            console.log(current, pageSize)
            this.pagination.current = current;
             this.pagination.pageSize = pageSize;
            this.getData(); //向后端发送请求
        },
    },
    
};
</script>
<style scoped>
    .search{display: flex;justify-content: space-between;}
    .addBtn{width: auto;}
    .action{display:inline-block;padding:5px 10px;margin:0 5px;background:#dceeff;border:1px solid #36a3ff;color:#36a3ff;border-radius:5px}
    .del{background:#ffdcdc;border-color:#ff4c4c;color:#ff4c4c}
    .showSpan{display: inline-block; padding:0 10px; border: 1px solid #bebebe;margin: 0 5px 3px;border-radius: 5px;}
    .lineSpan{color: #0003b1;padding: 0 5px;}
</style>

